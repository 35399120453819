import React, { useState } from "react"
import SearchModal from "./SearchModal"
import SEO from "./seo"

export default function App({ children, title }) {
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)

  return (
    <>
      <SEO title={title} />

      <nav className="fixed w-full bg-white z-10 border-b border-gray-200 shadow-md">
        <div className=" mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img src="/logo.png" alt="GoGo" className="h-10/12 mr-3" />
              <div className="flex-shrink-0 flex items-center hidden">
                <input
                  className="rounded-full border w-full py-2 px-6 text-gray-700 leading-tight focus:outline-none"
                  type="text"
                  placeholder="Search"
                  onFocus={() => setShow(true)}
                />
              </div>
            </div>
            <div className="hidden sm:-my-px sm:ml-6 sm:flex">
              <a
                href="https://gogo.rentals"
                className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
              >
                Home
              </a>
              <a
                href="https://gogo.rentals/host"
                className="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
              >
                Host an experience
              </a>
              <a
                href="/help-center"
                className="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
              >
                Help
              </a>
            </div>
            <div className="-mr-2 flex items-center sm:hidden">
              <button
                onClick={() => setOpen(!open)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    className={open ? "hidden" : "inline-flex"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                  <path
                    className={open ? "inline-flex" : "hidden"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className={open ? "block" : "hidden"}>
          <div className="pt-2 pb-3">
            <a
              href="https://gogo.rentals"
              className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >
              Home
            </a>
            <a
              href="https://gogo.rentals/host"
              className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
            >
              Host an experience
            </a>
            <a
              href="/help-center"
              className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
            >
              Help
            </a>
          </div>
        </div>
      </nav>

      {children}

      <div className="bg-white mt-12">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8  border-t">
          <div className="pt-12 grid grid-cols-1 md:grid-cols-5 gap-6 md:gap-8 xl:mt-0 xl:col-span-2">
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                About
              </h4>
              <ul className="mt-4">
                <li>
                  <a
                    href="/diversity-belonging/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Diversity &amp; Belonging{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/accessibility/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Accessibility{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/trust-safety/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Trust &amp; Safety{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/newsroom/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Newsroom{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Our Family
              </h4>
              <ul className="mt-4">
                <li>
                  <a
                    href="/united-we-stay/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    United We Stay{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/our-people/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Our People{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/what-we-believe/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    What We Believe{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                HOST
              </h4>
              <ul className="mt-4">
                <li>
                  <a
                    href="/check-in-check-out/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Check-In &amp; Check-Out{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/reviews/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Reviews{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/booking-approvals/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Booking Approvals{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/refunds/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Refunds{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Support
              </h4>
              <ul className="mt-4">
                <li>
                  <a
                    href="/help-center/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Help Centre{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/neighborhood/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Neighborhood{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Covid-19 info
              </h4>
              <ul className="mt-4">
                <li>
                  <a
                    href="/faq/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    FAQ{" "}
                  </a>
                </li>
                <li className="mt-4">
                  <a
                    href="/safety-measures/"
                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                  >
                    {" "}
                    Safety Measures{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 border-t my-12">
          <div className="border-gray-200 pt-4 text-center">
            <div className="copyright text-base">
              <span id="copyright" className="text-gray-500">
                © {new Date().getFullYear()} Penn Global Innovations, Inc. All
                Rights Reserved.
              </span>
              <span id="terms" className="text-blue ml-1">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/terms-and-conditions"
                  className="text-blue-600 hover:underline"
                >
                  Terms &amp; Conditions
                </a>
              </span>
              <span id="privacy" className="text-blue-600 ml-1">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/privacy-policy"
                  className="hover:underline"
                >
                  Privacy &amp; policy
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>

      {show && <SearchModal setShow={setShow} />}
    </>
  )
}
