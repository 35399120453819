import React, { useRef, useEffect, useState } from "react"
import debounce from "lodash/debounce"
import PropTypes from "prop-types"

function useOnClick(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener("mousedown", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
    }
  }, [ref, handler])
}

function SearchModal({ setShow }) {
  const ref = useRef()
  const [results, setResults] = useState([])
  const [searchQuery, SetSearchQuery] = useState("")

  useOnClick(ref, () => setShow(false))

  useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      const debouncedSearch = debounce(async () => {
        const lunr = await window.__LUNR__.__loaded
        const refs = lunr.en.index.search(searchQuery)
        const posts = refs.map(({ ref }) => lunr.en.store[ref])

        setResults(posts)
      }, 500)

      debouncedSearch()
    }

    if (!searchQuery) setResults([])
  }, [searchQuery])

  return (
    <div className="DocSearch DocSearch-Container">
      <div className="DocSearch-Modal" ref={ref}>
        <header className="DocSearch-SearchBar">
          <form action role="search" noValidate className="DocSearch-Form">
            <label
              className="DocSearch-MagnifierLabel"
              htmlFor="docsearch-input"
            >
              <svg
                width="20"
                height="20"
                className="DocSearch-Search-Icon"
                viewBox="0 0 20 20"
              >
                <path
                  d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                  stroke="currentColor"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </label>
            <div className="DocSearch-LoadingIndicator">
              <svg viewBox="0 0 38 38" stroke="currentColor" strokeOpacity=".5">
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)" strokeWidth={2}>
                    <circle strokeOpacity=".3" cx={18} cy={18} r={18} />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </g>
                </g>
              </svg>
            </div>
            <input
              className="DocSearch-Input"
              aria-autocomplete="list"
              aria-labelledby="docsearch-label"
              id="docsearch-input"
              placeholder="Search blogs"
              maxLength={64}
              type="search"
              value={searchQuery}
              onChange={e => SetSearchQuery(e.target.value)}
            />
            <button
              type="reset"
              title="Clear the query"
              className="DocSearch-Reset"
            >
              <svg width={20} height={20} viewBox="0 0 20 20">
                <path
                  d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                  stroke="currentColor"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </form>
          <button className="DocSearch-Cancel" onClick={() => setShow(false)}>
            Cancel
          </button>
        </header>
        <div className="DocSearch-Dropdown">
          <div className="DocSearch-Dropdown-Container">
            <section className="DocSearch-Hits">
              <div className="DocSearch-Hit-source"></div>
              <ul>
                {!!results.length &&
                  results.map(({ title, url, description }) => {
                    return (
                      <li className="DocSearch-Hit" key={title}>
                        <a href={`/${url}`}>
                          <div className="DocSearch-Hit-Container">
                            <div className="DocSearch-Hit-icon">
                              <svg width={20} height={20} viewBox="0 0 20 20">
                                <path
                                  d="M13 13h4-4V8H7v5h6v4-4H7V8H3h4V3v5h6V3v5h4-4v5zm-6 0v4-4H3h4z"
                                  stroke="currentColor"
                                  fill="none"
                                  fillRule="evenodd"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="DocSearch-Hit-content-wrapper">
                              <span className="DocSearch-Hit-path">
                                {description}
                              </span>
                              <span className="DocSearch-Hit-title">
                                {title}
                              </span>
                            </div>
                            <div className="DocSearch-Hit-action">
                              <svg
                                className="DocSearch-Hit-Select-Icon"
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                              >
                                <g
                                  stroke="currentColor"
                                  fill="none"
                                  fillRule="evenodd"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M18 3v4c0 2-2 4-4 4H2" />
                                  <path d="M8 17l-6-6 6-6" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </a>
                      </li>
                    )
                  })}
              </ul>
            </section>
            <section className="DocSearch-HitsFooter" />
          </div>
        </div>
        <footer className="DocSearch-Footer" />
      </div>
    </div>
  )
}

SearchModal.defaultProps = {}

SearchModal.propTypes = {
  setShow: PropTypes.func.isRequired,
}

export default SearchModal
